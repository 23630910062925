:root {
  /* Colors */
  --primaryColor: #5557a1;
  --onPrimaryColor: #ffffff;
  --secondaryColor: #008080;
  --onSecondaryColor: #ffffff;
  --surfaceColor: #e6e6e6;
  --onSurfaceColor: #0f0f0f;
  --backgroundColor: #fafafa;
  --onBackgroundColor: #0f0f0f;

  /* Reserved for dark theme 
  --backgroundColor: #1a1a1a;
  --onBackgroundColor: #fefefe;
  --surfaceColor: #292b2f;
  --onSurfaceColor: #fefefe; */

  --darkColor: #0f0f0f;
  --lightColor: #fefefe;

  /* z-indices */
  --lowIndex: 1;
  --midIndex: 10;
  --highIndex: 100;
  --higherIndex: 1000;
  --highestIndex: 10000;
  --maxIndex: 100000;

  /* Dimensions */
  --topBarHeight: 70px;
  --tabBarHeight: 40px;
  --sideBarWidth: 300px;
  --sideViewerWidth: 600px;
  --bigThumbnailWidth: 100%;
  --bigThumbnailHeight: 300px;
  --smallThumbnailWidth: 100%;
  --smallThumbnailHeight: 120px;
  --bottomViewerHeight: 400px;
  --imageSliderHeight: 300px;

  /* Shadows */
  --shadow1: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow2: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --shadow3: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  --shadow4: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  --shadow5: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

@media only screen and (min-width: 767px) {

  /* Styles for wider devices */
  :root {
    --imageSliderHeight: 500px;
  }
}

/* FONTS */

@font-face {
  font-family: "FontRegular";
  src: local("FontRegular"), url("./fonts/Quicksand/Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontBold";
  src: local("FontBold"), url("./fonts/Quicksand/Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontLight";
  src: local("FontLight"), url("./fonts/Quicksand/Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontSemiBold";
  src: local("FontSemiBold"), url("./fonts/Quicksand/SemiBold.ttf") format("truetype");
}

.font-bold {
  font-family: "FontBold" !important;
}

.font-regular {
  font-family: "FontRegular" !important;
}

.font-light {
  font-family: "FontLight" !important;
}

.font-semi-bold {
  font-family: "FontSemiBold" !important;
}

/* END of FONTS */

/* GENERAL STYLING */
body,
html {
  width: 100%;
  height: 100%;
  font-family: "FontRegular";
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  font-size: 1em;
}

/* Prevent body from when any modal is open */
body.modal-open {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {

  /* Styles for tablets in portrait and smaller devices */
  body.sidebar-open {
    overflow: hidden;
  }
}


#root {
  width: 100%;
  height: 100%;
}

/* UTILITIES */
.text-primary {
  color: var(--primaryColor) !important;
}

.text-secondary {
  color: var(--secondaryColor) !important;
}

.text-background {
  color: var(--backgroundColor) !important;
}

.text-surface {
  color: var(--surfaceColor) !important;
}

.text-dark {
  color: var(--darkColor) !important;
}

.text-light {
  color: var(--lightColor) !important;
}

.bg-gradient {
  background: linear-gradient(30deg, var(--primaryColor), var(--secondaryColor)) !important;
  color: var(--onPrimaryColor);
}

.bg-primary {
  background: var(--primaryColor) !important;
  color: var(--onPrimaryColor) !important;
}

.bg-secondary {
  background: var(--secondaryColor) !important;
  color: var(--onSecondaryColor) !important;
}

.bg-background {
  background: var(--backgroundColor) !important;
  color: var(--onBackgroundColor) !important;
}

.bg-surface {
  background: var(--surfaceColor) !important;
  color: var(--onSurfaceColor) !important;
}

.bg-dark {
  background: var(--darkColor) !important;
  color: var(--lightColor) !important;
}

.bg-light {
  background: var(--lightColor) !important;
  color: var(--darkColor) !important;
}

.btn {
  font-family: "FontSemiBold";
  font-size: 16px;

}

.btn-sm {
  font-size: 12px !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  background: var(--primaryColor) !important;
  color: var(--onPrimaryColor) !important;
  border: 2px solid var(--primaryColor) !important;
}

.btn-secondary {
  background: var(--secondaryColor) !important;
  color: var(--onSecondaryColor) !important;
  border: 2px solid var(--secondaryColor) !important;
}

.btn-background {
  background: var(--backgroundColor) !important;
  color: var(--onBackgroundColor) !important;
  border: 2px solid var(--backgroundColor) !important;
}

.btn-surface {
  background: var(--surfaceColor) !important;
  color: var(--onSurfaceColor) !important;
  border: 2px solid var(--surfaceColor) !important;
}

.btn-dark {
  background: var(--darkColor) !important;
  color: var(--lightColor) !important;
  border: 2px solid var(--darkColor) !important;
}

.btn-light {
  background: var(--lightColor) !important;
  color: var(--darkColor) !important;
  border: 2px solid var(--lightColor) !important;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-padded {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.btn-outline-dark {
  color: var(--darkColor) !important;
  border: 2px solid var(--darkColor) !important;
  background: none;
}

.btn-outline-light {
  color: var(--lightColor) !important;
  border: 2px solid var(--lightColor) !important;
  background: none;
}

.btn-outline-primary {
  color: var(--primaryColor) !important;
  border: 2px solid var(--primaryColor) !important;
  background: none;
}

.btn-outline-secondary {
  color: var(--secondaryColor) !important;
  border: 2px solid var(--secondaryColor) !important;
  background: none;
}

.btn-outline-surface {
  color: var(--surfaceColor) !important;
  border: 2px solid var(--surfaceColor) !important;
  background: none;
}

.btn-outline-background {
  color: var(--backgroundColor) !important;
  border: 2px solid var(--backgroundColor) !important;
  background: none;
}

.btn-padded {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mOneLineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mNoMargin {
  margin: 0 !important;
}

.mNoPadding {
  padding: 0 !important;
}

.mSupportLoading {
  min-height: 60px !important;
  min-width: 40px;
  position: relative;
}

.mSupportLoadingFull {
  height: 100% !important;
  width: 100%;
  position: relative;
}

.mShadow1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.mShadow2 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mShadow3 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.mShadow4 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.mShadow5 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mNoScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mNoScrollBar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mMobileOnly {
  display: none;
}

.mDesktopOnly {
  display: block;
}

.mSectionTitle {
  font-size: 24px;
  font-family: "FontSemiBold";
  margin: 0;
  margin-top: 40px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.three-lines-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 767px) {
  .mMobileOnly {
    display: block;
  }

  .mDesktopOnly {
    display: none;
  }
}

label {
  font-family: "FontSemiBold";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* For Firefox */
}

pre {
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 0;
  white-space: pre-wrap;
  /* Preserve newlines, but allow wrapping */
  word-wrap: break-word;
  /* Break long words */
  word-break: break-all;
  /* Ensure long continuous text breaks */
  overflow-wrap: break-word;
  /* Standard way to break text for overflow */
  max-width: 100%;
}

input,
select,
textarea {
  background: none !important;
  color: inherit !important;
  border: 1px solid var(--darkColor) !important;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid var(--primaryColor) !important;
  background: none !important;
  color: inherit !important;
}

input::placeholder,
textarea::placeholder {
  color: inherit !important;
  opacity: 0.5 !important;
}

.card {
  border-radius: 15px !important;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  border: none !important;
  box-shadow: var(--shadow1);
  margin-bottom: 20px;
  overflow: hidden;
}

.card .card-title {
  font-size: 16px;
  font-family: "FontSemiBold";
  color: var(--primaryColor);
}

[class^="col-"] {}



/* end of UTILITIES */


/* Loader */
.Loader {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 15px;
  background: none;
  border: 4px solid var(--onBackgroundColor) !important;
  border-left: 4px solid var(--backgroundColor) !important;
  top: 50%;
  z-index: inherit;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  animation-name: "loading";
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: "loading";
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* MainLoader */
.MainLoader {
  width: 100vw;
  height: 100vh;
}

/* OverlayLoader */
.OverlayLoader {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: var(--maxIndex);
  top: 0;
  left: 0;
}

/* Backdrop */
.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  /* Slightly dark backdrop */
  z-index: var(--lowIndex);
  /* Behind all fixed components */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

/* Viewer */
.Viewer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--midIndex);
  overflow: auto;
  top: 0;
  left: 0;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translateY(-100vh);
  /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;
}


.Viewer.visible {
  opacity: 1;
  transform: translateY(0);
  /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.Viewer.hiding {
  opacity: 0;
  transform: translateY(-100vh);
  /* Slide back up */
  visibility: visible;
  /* Keep visible until animation ends */
  pointer-events: none;
  /* Disable interactions during hide animation */
}


.Viewer>.header {
  width: 100%;
  position: absolute;
  height: calc(0.7 * var(--topBarHeight));
  top: 0;
  left: 0;

}

.Viewer>.body {
  width: 100%;
  position: absolute;
  height: calc(100% - (0.7 * var(--topBarHeight)));
  overflow: auto;
  top: calc(0.7 * var(--topBarHeight));
  left: 0;
  padding: 0;
}

.Viewer>.body>.container {
  padding: 20px !important;
}

/* SideViewer */
.SideViewer {
  width: var(--sideViewerWidth);
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--higherIndex);
  overflow: auto;
  top: 0;
  right: 0;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translateX(100vw);
  /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {

  /* Styles for tablets in portrait and smaller devices */
  .SideViewer {
    width: 95vw;
  }
}

.SideViewer.visible {
  opacity: 1;
  transform: translateX(0);
  /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.SideViewer.hiding {
  opacity: 0;
  transform: translateX(100vw);
  /* Slide back up */
  visibility: visible;
  /* Keep visible until animation ends */
  pointer-events: none;
  /* Disable interactions during hide animation */
}

.SideViewer>.header {
  width: 100%;
  position: absolute;
  height: var(--topBarHeight);
  top: 0;
  left: 0;

}

.SideViewer>.body {
  width: 100%;
  position: absolute;
  height: calc(100% - var(--topBarHeight));
  overflow: auto;
  top: var(--topBarHeight);
  left: 0;
}

/* BottomViewer */
.BottomViewer {
  width: 100%;
  height: var(--bottomViewerHeight);
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--highestIndex);
  overflow: hidden;
  bottom: 0;
  left: 0;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translateY(100vh);
  /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.6s ease-out;
  visibility: hidden;
  pointer-events: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.BottomViewer.visible {
  opacity: 1;
  transform: translateY(0);
  /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.BottomViewer.hiding {
  opacity: 0;
  transform: translateY(100vh);
  /* Slide back up */
  visibility: visible;
  /* Keep visible until animation ends */
  pointer-events: none;
  /* Disable interactions during hide animation */
}

.BottomViewer>.header {
  width: 100%;
  position: absolute;
  height: 20px;
  top: 0;
  left: 0;
  overflow: hidden;
}

.BottomViewer>.body {
  width: 100%;
  position: absolute;
  height: calc(100% - 20px);
  overflow: auto;
  top: 20px;
  left: 0;
  padding: 0;
}


/* ConfirmDialog */
.ConfirmDialog {
  width: 300px;
  height: 240px;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--maxIndex);
  overflow: hidden;
  top: 50%;
  left: 50%;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translate(-100%, -50%);
  /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;
}

.ConfirmDialog.visible {
  opacity: 1;
  transform: translate(-50%, -50%);
  /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.ConfirmDialog.hiding {
  opacity: 0;
  transform: translate(-100%, -50%);
  /* Slide back up */
  visibility: visible;
  /* Keep visible until animation ends */
  pointer-events: none;
  /* Disable interactions during hide animation */
}

.ConfirmDialog>.header {
  width: 100%;
  position: absolute;
  height: 20%;
  top: 0;
  left: 0;

}

.ConfirmDialog>.body {
  width: 100%;
  position: absolute;
  height: 55%;
  overflow: hidden;
  top: 20%;
  left: 0;

}

.ConfirmDialog>.footer {
  width: 100%;
  position: absolute;
  height: 25%;
  overflow: hidden;
  top: 75%;
  left: 0;

}

/* TabbedView */
.TabbedView {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.TabbedViewNavigation {
  height: var(--tabBarHeight);
  padding: 0;
}

.TabbedViewNavigation>.container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: inherit;
  padding: 10px;
}

.TabbedViewNavigationButton {
  flex: 1;
  padding: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 20px;
  transition: all .3s ease-in;
  font-family: "FontBold" !important;
  box-shadow: none;

  /* Ensure single line text with ellipsis for overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Align text to center */
  display: flex;
  align-items: center;
  justify-content: center;
}

.TabbedViewNavigationButton.active {
  color: var(--onPrimaryColor);
  background-color: var(--primaryColor);
  font-family: "FontBold";
}

.TabbedViewContent {
  height: calc(100% - var(--tabBarHeight));
  overflow: auto;
  position: relative;
  background-color: inherit;
  padding: 0;
}



/* TopBar */
.TopBar {
  width: 100%;
  height: var(--topBarHeight);
  background: var(--backgroundColor);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--lowIndex);
  padding: 10px;
  overflow: hidden;
}

.TopBar>.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  padding: 5px;
}

.TopBar>.body .mIcon {
  min-width: 30px;
  flex-grow: 1;
  height: 30px;
  align-self: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.TopBar>.body .mIcon>.mSymbol {
  font-size: 30px;
  color: var(--primaryColor);
}

/* BottomBar */
.BottomBar {
  width: 100%;
  height: var(--topBarHeight);
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  position: fixed;
  bottom: 0;
  box-shadow: var(--shadow3);
  left: 0;
  z-index: var(--lowIndex);
  padding: 0px;
  overflow: hidden;
}

.BottomBar>.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  padding: 5px;
}

.BottomBar>.body .mIcon {
  width: 25%;
  height: auto;
  align-self: center;
  margin: 0;
  margin-left: 15px;
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  align-self: center;
  border-radius: 10px;
}

.BottomBar>.body .mIcon[data-active='true'] {
  color: var(--onPrimaryColor);
  background: var(--primaryColor);
}

.BottomBar>.body .mIcon>.mSymbol {
  font-size: 30px;
  margin: 0 !important;
}

.BottomBar>.body .mIcon>.mText {
  font-size: 10px;
  margin: 0 !important;
  font-family: "FontBold";
}

/* MainBody */
.MainBody {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  padding-top: var(--topBarHeight);
  overflow: scroll;
}

.MainBody>.container {
  padding-top: 10px !important;
}


/* Homepage */
.Homepage {
  width: 100%;
  height: 100%;
}



/* Login */
.Login {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  background: linear-gradient(to bottom right,
      var(--backgroundColor) 70%,
      var(--primaryColor) 30%);
}

.Login>.launch {
  height: 100%;
  width: 100%;
  padding: 10px;
}

/* HomeLanding */
.HomeLanding {
  width: 100%;
  height: 100%;
}

/* OneArticle */
.OneArticle.big {
  width: var(--bigThumbnailWidth);
  height: var(--bigThumbnailHeight);
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  margin: 0;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.OneArticle.big>.cover {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      /* Fully transparent at the top */
      rgba(0, 0, 0, 0.25) 50%,
      /* Semi-transparent in the middle */
      rgba(0, 0, 0, 0.8) 100%
      /* Fully dark at the bottom */
    );
  color: var(--lightColor);
}

.OneArticle.big>.cover>.title {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  /* Ensures proper line wrapping */
  padding: 10px;
  font-size: 18px;
  font-family: "FontBold";
  line-height: 26px;
  max-height: calc((26px * 3) + 12px);
  position: absolute;
  bottom: 10px;
  left: 0;
  text-transform: capitalize;
}

.OneArticle.small {
  width: var(--smallThumbnailWidth);
  height: var(--smallThumbnailHeight);
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: pointer;
}

.OneArticle.small>.cover {
  flex-shrink: 0;
  width: var(--smallThumbnailHeight);
  height: var(--smallThumbnailHeight);
  border-radius: 20px;
  overflow: hidden;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  align-self: center;
  background-position: center;
  background-size: cover;
}

.OneArticle.small>.title {
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  /* Ensures proper line wrapping */
  padding: 10px;
  font-size: 18px;
  font-family: "FontBold";
  line-height: 22px;
  max-height: calc((22px * 4) + 12px);
  text-transform: capitalize;
}

/* One */
.One {
  width: 100%;
  padding: 20px;
  height: auto;
  cursor: pointer;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  border-bottom: 3px solid var(--surfaceColor);
}

.One:hover {
  background-color: var(--surfaceColor);
  color: var(--onSurfaceColor);
}

/* PdfReaderBar */
.PdfReaderBar {
  width: 100%;
  height: calc(var(--topBarHeight) * 0.7);
  background: var(--backgroundColor);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--highestIndex);
  padding: 0px;
  overflow: hidden;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
}